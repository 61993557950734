import { StaticImage } from "gatsby-plugin-image"
import * as React from "react"

import Layout from "../components/layout"
import Pathway from "../components/pathway"
import Seo from "../components/seo"

const AboutUsPage = () => (
  <Layout>
    <Seo title="Chi siamo" />

    <section className="relative overflow-x-hidden py-20">
      <div className="container mx-auto px-4">
        <h2 className="mt-4 mb-16 font-bold font-heading lg:px-20 text-5xl">
          Chi siamo
        </h2>
        <div className="relative md:h-96 mb-10 md:mb-20">
          <div className="absolute top-1/2 transform -translate-y-1/2 left-0 right-0 h-20 md:h-80 w-full bg-gray-100"></div>
          <div className="px-6">
            <StaticImage
              className="w-full md:h-96 object-cover"
              style={{ position: "relative" }}
              src="../images/pages/about/header.png"
              placeholder="blurred"
              alt="header"
            />
          </div>
        </div>
        <div className="max-w-3xl mx-auto">
          <h3 className="mb-12 text-xl">
            La società MONTEBELLO GROUP è stata fondata nel 2022, infatti per le
            fonti giuste di materie prime e per l’esperienza nella vendita di
            materie prime.
          </h3>
          <p className="mb-10">
            Negli ultimi anni abbiamo acquisito sempre più esperienza nel
            settore del commercio di materie prime in diversi settori. La nostra
            serietà nel lavoro ci ha reso un punto fermo per i nostri clienti,
            soddisfacendo sempre ogni richiesta.
          </p>
          <p className="mb-10">
            Siamo distributori di oli essenziali di prima qualità, tra cui il
            prodotto principale è l’olio CBD, sia come materia prima sia come
            prodotto ad uso alimentare. Siamo distributori dei prodotti
            MULTITRANCE , AMSTERDAM che coprono tutte le richieste del mercato a
            360 gradi.
          </p>
          <p className="mb-10">Siamo grandi e saremo ancora più grandi.</p>
          <p className="">
            Lavoriamo con tanti paesi d'Europa, i nostri clienti e fornitori
            sono tutti soddisfatti dopo più anni di collaborazione.
          </p>
        </div>
      </div>
    </section>

    <Pathway />
  </Layout>
)

export default AboutUsPage
